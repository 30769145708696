// @flow
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom'; //ADP
import { inject, observer } from 'mobx-react';
import { withRouter, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import moment from 'moment-timezone';
import BackButton from '../../Elements/BackButton';
import BarChart from '../../BarChart/BarChart';
import AnimatedUsage from '../../Elements/AnimatedUsage';
import { apiUrl } from '../../../config/constants';
import TimeframeSelector from '../../Elements/TimeframeSelector';
import NavigationArrows from '../../Elements/NavigationArrows';
import fillDates from '../../../utils/fillDates';
import LoginStore from '../../../store/LoginStore';
import { UsageData, Customer, Contract, DataPoint } from '../../../store/index';
import { Tick } from '../../BarChart/index';
import Loading from './Loading';
import getUsageData from '../../../utils/getUsageData';
import ExcelButton from '../../Elements/ExcelButton';
import { ReactComponent as Chevron } from '../../../assets/images/chevron-up.svg';
//import { makeMockProductionData } from '../../../utils/productionDataMock';
//import { makeMockQuarterHourData } from '../../../utils/quarterlyHourDataMock'

// Set Finnish locale, required for month names
import 'moment/locale/fi';
import buildQueryString from '../../../utils/buildQueryString';
import TotalUsageCircle from '../Company/TotalUsageCircle';
import { getCompanyUserDetails } from '../../../actions/b2bUsers';
import { Customerships } from '../../../types/companies';
moment.locale('fi');

type Props = {
  LoginStore: typeof LoginStore;
  customer?: Customer;
  contract: Contract;
  id: string;
  type: string;
  location: RouteComponentProps["location"];
  match: RouteComponentProps["match"];
  history: RouteComponentProps["history"];
};

type Params = {
  [key: string]: string;
  start: string;
  end: string;
  level: string;
  frame: string;
}


type State = {
  axisClass: string;
  barsHidden: boolean;
  errorMessage: string;
  loading: boolean;
  sum: number;
  productionSum?: number | undefined;
  tick?: Tick | null;
  timeframe: string;
  usageData?: UsageData | null | undefined;
  showComparison: boolean;
  timeframeExceeded: boolean;
  goToDashboard: boolean;
  isCompany: boolean;
  companies: Customerships[] | null;
  showInfo: boolean;
  frontPageLink: string;
  maximumResolution: string;
  unit: string;
};

const subtractOne = (ts: string, frame: moment.DurationInputArg2) =>
  moment(ts)
    .subtract(1, frame)
    .format('YYYY-MM-DD');

const mergeForComparison = (data: DataPoint[], data2: DataPoint[] | undefined | false | null) => {
  let i = 0;
  while (data && i < data.length) {
    if (!data2 || i === data2.length) break;
    data[i].comparison = { v: data2[i].v, r: data2[i].r, ts: data2[i].ts, vp: data2[i]?.vp };
    ++i;
  }
  return data;
};

class DetailsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sum: 0,
      timeframe: '',
      barsHidden: true,
      axisClass: '',
      errorMessage: '',
      usageData: null,
      loading: true,
      tick: null,
      showComparison: false,
      timeframeExceeded: false,
      goToDashboard: false,
      isCompany: false,
      companies: null,
      showInfo: false,
      frontPageLink: '',
      maximumResolution: '',
      unit: ''
    };

    this.getFrontpageLink = this.getFrontpageLink.bind(this);

  }

  parseQueryString = (qs: string): Params => {
    const withoutQuestionMark = qs.substring(1, qs.length);

    const paramsArray: string[] = withoutQuestionMark.split('&');
    return paramsArray
      .map((param: string) => param.split('='))
      .reduce((acc: Params, cur) => {
        acc[cur[0]] = cur[1];
        return acc;
      }, { start: "", end: "", level: "", frame: "" });
  };

  updateRoute = (pathname: string, usageData?: UsageData) => {
    if (usageData) {
      const history = createBrowserHistory();
      const params = {
        start: usageData.start,
        end: usageData.end,
        frame: usageData.frame,
        level: usageData.level,
      };
      history.push(pathname + buildQueryString(params));
    }
  };
  getChartDataByUsageData = async (usageData: UsageData) => {
    if (usageData) {
      const filledData: DataPoint[] | null | undefined = await this.loadData(
        usageData.start,
        usageData.end,
        usageData
      );
      const filledData2 =
        this.state.showComparison &&
        (await this.loadData(
          subtractOne(usageData.start, 'year'),
          subtractOne(usageData.end, 'year'),
          usageData
        ));
      if (filledData) {
        this.setState({
          barsHidden: true,
          usageData: {
            ...usageData,
            data: mergeForComparison(filledData, filledData2),
          },
          loading: false,
        });
        this.handleData();
      }
    }
  };

  handleShowInfo = () => {
    this.setState({ showInfo: !this.state.showInfo });
  }

  componentDidMount = async () => {
    window.scroll(0, 0);

    const companyPath = window.location.pathname.split('/')[1] === 'yritys';
    const role = this.getFrontpageLink(LoginStore.type);
    this.setState({ frontPageLink: role })

    if (companyPath) {
      const companies = (await getCompanyUserDetails()).companies;
      this.setState({ isCompany: true, companies });
    }

    if (this.props.LoginStore.type == "consumer") {
      await LoginStore.login('consumer', true);
    } else {
      await LoginStore.login('company', true);
    }

    // Get type from props (manager) or from pathname (consumer)
    const type =
      this.props.type ||
      window.location.pathname.split('/')[companyPath ? 2 : 1];
    const getInitialUsageData = async (): Promise<UsageData | null> => getUsageData(type);
    const initialUsageData = await getInitialUsageData();

    if (initialUsageData) {
      await this.getChartDataByUsageData(initialUsageData);
      this.updateRoute(this.props.location.pathname, initialUsageData);
    }
    const history = createBrowserHistory();
    this.backListener = history.listen.call(this, async (location, action) => {
      if (action === 'POP') {
        const { usageData } = this.state;
        const { search } = location;

        const params: Params = this.parseQueryString(search);

        if (!params.start || !params.end || !params.level || !params.frame) {
          this.setState({
            goToDashboard: true,
          });
        } else {
          const { start, end, frame, level } = this.parseQueryString(search);
          const newUsageData = {
            ...usageData as UsageData,
            start,
            end,
            frame,
            level,
          };
          if (usageData?.data) { await this.getChartDataByUsageData(newUsageData); }
        }
      }
    });

  };
  componentWillUnmount() {
    this.backListener();
  }
  onRouteChange = (/*location, action*/) => {
    // console.log(location, action)
  };

  updateUsageData = (usageData: UsageData) => {
    this.getChartDataByUsageData(usageData);
    this.updateRoute(this.props.location.pathname, usageData);
  };

  loadData = async (
    start_s: string,
    end_s: string,
    usageData?: UsageData
  ): Promise<DataPoint[] | null | undefined> => {
    // Hide bars for animation
    //this.setState({ barsHidden: true })
    const { LoginStore, match } = this.props;
    if (!usageData) return null;

    const id = this.props.id || match.params.itemId;
    const companyID = match.params.companyId;
    let start = '';
    let end = '';

    // Set API call date format by usage period
    if (usageData.frame === 'year') {
      start = moment(start_s).format('YYYY-MM');
      end = moment(end_s).format('YYYY-MM');
    }

    else if (usageData.level === 'quarter-hour') {
      start = encodeURIComponent(moment(start_s).format('YYYY-MM-DD HH:mm:ss'));
      end = encodeURIComponent(moment(end_s).format('YYYY-MM-DD HH:mm:ss'));
    }
    else {
      start = moment(start_s).format('YYYY-MM-DD');
      end = moment(end_s).format('YYYY-MM-DD');
    }
    const path = usageData.level;

    const req = new Request(
      `${apiUrl}/${usageData.type}/${path}?start=${start}&end=${end}&mp=${id}&companyid=${companyID}&logintype=${this.props.LoginStore.type}`,
      {
        method: 'GET',
        mode: 'cors',
      }
    );

    try {
      const response = await fetch(req);
      // add error handling for unavailabily of quarter-hour data
      if (path === "quarter-hour" && response.status === 404) {
        // console.log('no data recieved')
        this.setState({
          errorMessage:
            'Valitettavasti tarkempaa tietoa ei ole saatavilla tälle ajanjaksolle.',
          timeframeExceeded: false,
          loading: false
        });
        window.scrollTo(0, 100);
        setTimeout(() => history.back(), 4000);
      }
      else if (response.status === 401) {
        // Logout if unauthorized
        this.setState({
          errorMessage:
            'Ei oikeuksia tietojen lataamiseen, kirjaudutaan ulos...',
          timeframeExceeded: false,
          loading: false
        });
        LoginStore.logout();
        window.location.assign(window.location.origin);
        return null;
      }
      else if (response.status === 400) {
        this.setState({
          errorMessage:
            'Kulutuslukemia voi hakea enintään yhden vuoden ajalta. Päivitä sivu päästäksesi takaisin kulutuslukemiin',
          timeframeExceeded: true,
          loading: false
        });
      } else if (response.status === 403) {
        // Logout if invalid api call
        this.setState({
          errorMessage: 'Virhe tietoja ladatessa, kirjaudutaan ulos...',
          timeframeExceeded: false,
          loading: false
        });
        LoginStore.logout();
        return null;
      } else if (response.ok) {
        this.setState({
          errorMessage: '',
          timeframeExceeded: false,
          loading: false
        });

        const result = await response.json();
        const { data, maximumResolution, unit } = result
        this.setState({ maximumResolution, unit });

        // Add dates without datapoints
        const filledData: DataPoint[] | null = fillDates(start_s, end_s, usageData.level, data);
        if (!filledData) return null;


        return filledData;


      } else {
        this.setState({
          errorMessage: 'Virhe tietoja ladatessa',
          timeframeExceeded: false,
          loading: false
        });
      }
    } catch (error) {
      console.error('Error fetching usage data');
      this.setState({
        errorMessage: 'Virhe tietoja ladatessa',
        timeframeExceeded: false,
        loading: false
      });
    }
  };

  toggleComparison = (/*e: SyntheticEvent<HTMLFormElement>*/) => {
    const show = !this.state.showComparison;
    this.setState({
      showComparison: show,
    });
    if (show && this.state.usageData) {
      this.updateUsageData(this.state.usageData);
    }
  };

  handleData = (): null => {
    const { usageData } = this.state;
    if (!usageData || !usageData.data) return null;

    const sumWholeNumber = usageData.data
    .map(datapoint => datapoint.v)
    .reduce((total, amount) => total + amount, 0);
    /**
     * We use Math.round here to show decimals instead of toFixed()
     * to keep value as a number.
     * We dont want to switch the type to string since the animatedvalue
     * component doenst support string values.
    */
    const sum = usageData.type === 'electricity'
      ? Math.round(sumWholeNumber * 100) / 100
      : Math.round(sumWholeNumber * 1000) / 1000;

    // Total production sum -ADP
    const productionSum = () => {
      const totalProduction = usageData.data
        .map(datapoint => datapoint.vp as number)
        .reduce((total, amount) => total + amount, 0);

      const roundedProductionSum = usageData.type === 'electricity'
        ? Math.round(totalProduction * 100) / 100
        : Math.round(totalProduction * 1000) / 1000;

      return roundedProductionSum;
    };

    // Get and format timeframe shown on top of the graph
    const timeframe = this.getPeriod(usageData);

    // Format data timestamps on x-axis
    const tick = this.formatTimestamps(usageData);

    // Get graph className used for axis formatting
    // axis-1 shows all values, axis-2 every other value and so forth
    const axisClass = this.getGraphClassName(usageData);

    this.setState({
      axisClass: axisClass,
      sum: sum,
      productionSum: productionSum(),
      tick: tick,
      timeframe: timeframe,
      barsHidden: false,
    });

    return null;
  };

  getPeriod = (usageData: UsageData): string => {
    // Get timestamps
    let firstTimestamp;
    let lastTimestamp;

    // Format timestamps
    if (usageData.frame === 'year') {
      firstTimestamp = moment
        .utc(usageData.start)
        .startOf('month')
        .format('DD.MM.YYYY');
      lastTimestamp = moment
        .utc(usageData.end)
        .endOf('month')
        .format('DD.MM.YYYY');
    }      // add timestamp format for quarterhourly data -ADP
    else if (usageData.frame === 'hour') {
      firstTimestamp = moment.utc(usageData.start).format('DD.MM.YYYY HH:mm');
      lastTimestamp = moment.utc(usageData.end).format('HH:mm');
    }
    else {
      firstTimestamp = moment.utc(usageData.start).format('DD.MM.YYYY');
      lastTimestamp = moment.utc(usageData.end).format('DD.MM.YYYY');
    }

    let period;
    // Build timestamp string
    if (usageData.frame === 'custom') {
      // Use first and last value
      period = `${firstTimestamp} - ${lastTimestamp}`;
    } else if (usageData.level === 'hour') {
      // Show only last value
      period = lastTimestamp;
    } else {
      // Use first and last value
      period = `${firstTimestamp} - ${lastTimestamp}`;
    }

    return period;
  };

  formatTimestamps = (usageData: UsageData): Tick => {
    const period = usageData.level;
    const tick = {
      labelFormat: 'DD.MM',
      hoverFormat: 'DD.MM.',
      length: 0,
      hoverFormatExtended: 'DD.MM.YY',
    };
    if (period === 'hour') {
      tick.labelFormat = 'HH';
      tick.hoverFormat = 'DD.MM.YYYY HH:mm';
      tick.hoverFormatExtended = 'DD.MM.YYYY HH:mm';
    }
    else if (period === 'quarter-hour') {
      tick.labelFormat = 'HH:mm';
      tick.hoverFormat = 'HH:mm';
      tick.hoverFormatExtended = 'DD.MM.YYYY HH:mm';
    }
    else if (period === 'month') {
      tick.labelFormat = 'MMM';
      tick.hoverFormat = 'MMMM';
      tick.hoverFormatExtended = 'MMMM YYYY';
      tick.length = 3;
    } else {
      tick.labelFormat = 'DD.MM.';
      tick.hoverFormat = 'DD.MM.';
      tick.hoverFormatExtended = 'DD.MM.YY';
    }

    return tick;
  };

  getFrontpageLink = (role: string) => {
    switch (role) {
      case 'consumer':
        return '/dashboard';
      case 'remUser':
        return '/isannointi/dashboard';
      case 'remCompany':
        return '/paakayttaja/dashboard';
      default:
        return '';
    }
  };

  getGraphClassName = (usageData: UsageData): string => {
    // Determine graph className used to determine timestamp frequency
    if (usageData.frame === 'custom' && usageData.level === 'hour') {
      return 'axis-0';
    } else if (usageData.level === 'hour') {
      return 'axis-0';
    } else if (usageData.frame === 'year') {
      // Show all months
      return 'axis-0';
    } else {
      // Determine value based on number of datapoints
      // Under 12 datapoints = axis-0
      return `axis-${Math.ceil(usageData.data.length / 12)}`;
    }
  };

  render() {
    const {
      usageData,
      loading,
      errorMessage,
      timeframe,
      tick,
      barsHidden,
      axisClass,
      sum,
      timeframeExceeded,
      goToDashboard,
      maximumResolution,
      unit
    } = this.state;
    const { LoginStore, customer, contract, match } = this.props;

    //moved this to state instead -ADP
    //const frontPageLink = this.getFrontpageLink(LoginStore.type);

    if (goToDashboard) return <Redirect to={this.state.frontPageLink} />;

    const wrapperClass = `wrapper detailspage ${window.location.pathname.split('/')[this.state.isCompany ? 2 : 1]
      } ${this.state.isCompany ? 'consumption-view container' : ''}`;

    const id = this.props.id || match.params.itemId;
    const companyID = match.params.companyId;

    const barsClassnames = barsHidden
      ? 'barchart-wrapper hidden ' + axisClass
      : 'barchart-wrapper visible ' + axisClass;

    const contractData = LoginStore.customerData.find(customer =>
      customer.contracts.some(contract =>
        contract.meteringpointIdentifier === id
      )
    );
    const singleContractData = contractData?.contracts.find(contract => contract.meteringpointIdentifier === id);
    const contractFromId = singleContractData;
    const companyName = contractData?.customerName;
    const meteringPoint = singleContractData?.meteringpointIdentifier;
    const meteringPointAddress = `${singleContractData?.address}, ${singleContractData?.zip} ${singleContractData?.city}`;
    const meteringPointGSRN = singleContractData?.gsrnIdentifier;

    if (loading) {
      return <Loading message="Ladataan käyttötietoja..." />;
    }
    if (timeframeExceeded) {
      return <p className="errortext">{errorMessage}</p>;
    }
    if (!usageData || !usageData.data) {
      return <p className="errortext">Virhe käyttötietoja ladatessa</p>;
    }
    return (
      <div className={wrapperClass}>
        {errorMessage ? (
          <p className="errortext">{errorMessage}</p>
        ) : (
          <>
            {this.state.isCompany ? (
              <div className="consumption-view-header">
                <button
                  className="back-button"
                  onClick={() => this.props.history.push('/yritys/yleisnakyma')}
                >
                  <Chevron />
                  Takaisin
                </button>
                <div className="title-wrapper">
                  <h1>
                    {meteringPointAddress} <br />
                    {!meteringPointGSRN ? (
                      <>{`Käyttöpaikka: ${meteringPoint}`}</>
                    ) : (
                      <>{`Käyttöpaikkatunnus GSRN: ${meteringPointGSRN}`}</>
                    )}
                    <br />
                    {usageData.type === 'electricity' ? 'Sähkönkulutus' : 'Kaukolämmön kulutus'}
                  </h1>
                  <p>{companyName}</p>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="btn-row">
                  <BackButton to={this.state.frontPageLink} />
                  <h1 className="details-header">{usageData.title}</h1>
                </div>
              </div>
            )}
            <div className={this.state.isCompany ? 'actions-and-data-wrapper' : ''}>
              <div className={this.state.isCompany ? 'actions-and-data' : ''}>
                <div className="time-selection">
                  {this.state.isCompany ? <p>Näytä</p> : null}
                  <TimeframeSelector
                    usageData={usageData}
                    updateUsageData={this.updateUsageData}
                    userType={LoginStore.type}
                    isCompany={this.state.isCompany}
                  />
                </div>

                {this.state.isCompany ? (
                  <TotalUsageCircle sum={sum} heading={'Kulutus'} unit={unit} />
                ) : <></>}
              </div>

              <div className={this.state.isCompany ? "comparison-select-wrapper" : "comparison-select-flex-wrapper"}>
                <div className="comparison-select">
                  <input
                    type="checkbox"
                    id="showComparison"
                    checked={!!this.state.showComparison}
                    onChange={this.toggleComparison}
                  />
                  <label htmlFor="showComparison">Vertaa vuoden takaiseen</label>
                </div>

                {usageData.type === 'electricity' && (
                  <div className={this.state.showInfo ? "data-info-wrapper expand" : "data-info-wrapper"} onClick={this.handleShowInfo}>
                    <i className={`fa fa-info-circle`} title="lisää tietoja" />
                    <div className="data-info">
                      Kulutusmittaukset 23.01.01 jälkeen esitetään nettosummana, eli tuotantotiedot on vähennetty kokonaissummasta
                    </div>
                  </div>
                )}
              </div>

              {this.state.isCompany ? (
                <></>
              ) : (
                <AnimatedUsage usageSum={sum} type="heat" heading={'Kulutus'} />
              )}
            </div>

            <React.Fragment>
              <NavigationArrows
                usageData={usageData}
                updateUsageData={this.updateUsageData}
              />
              <div className="container">
                <div className={barsClassnames}>
                  <BarChart
                    data={usageData.data}
                    unit={unit}
                    usageData={usageData}
                    timeframe={timeframe}
                    id={id}
                    height="400"
                    tick={tick || null}
                    showComparison={this.state.showComparison}
                    updateUsageData={this.updateUsageData}
                    contractFromId={contractFromId}
                    maximumResolution={maximumResolution}
                  />
                </div>
    
                {this.state.isCompany && usageData.type !== 'heat' ? (
                  <TotalUsageCircle sum={this.state.productionSum as number} heading={'Tuotanto'} unit={unit} />
                ) : (
                  usageData.type !== 'heat' && (
                    <>
                      <AnimatedUsage usageSum={this.state.productionSum as number} type="heat" heading={'Tuotanto'} />
                      <p className="graph-usage-unit">{unit}</p>
                    </>
                  )
                )}

                <ExcelButton
                  id={id}
                  type={usageData.type}
                  usageData={usageData}
                  customer={customer}
                  contract={contract}
                  LoginStore={LoginStore}
                  isCompany={this.state.isCompany}
                  companyID={companyID}
                />
              </div>
            </React.Fragment>
          </>
        )}
      </div>
    );
  }
}

export default withRouter(inject('LoginStore')(observer(DetailsPage)));
