// @flow
import * as React from 'react'
import { apiUrl } from '../../../config/constants'
import LoginStore from '../../../store/LoginStore'
import { inject, observer } from 'mobx-react/index'
import getHeaders from '../../../utils/getHeaders'

// TODO Determine correct types.
type Props = {
  LoginStore: typeof LoginStore
  match: any // eslint-disable-line
}

type State = {
  buttonText: string
  comment: string
  email: string
  error: string
  messageSent: boolean
  loading: boolean
}

class Feedback extends React.Component<Props, State> {
  constructor() {
    super()
    this.state = {
      buttonText: 'Lähetä',
      comment: '',
      email: '',
      error: '',
      messageSent: false,
      loading: false,
    }
  }

  componentDidMount = async () => {
    const { LoginStore } = this.props
    const { type } = this.props.match.params
    if (!LoginStore.logged) {
      await LoginStore.login(type, true)
    }
  }

  handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    event.target.id === 'email'
      ? this.setState({ email: event.target.value })
      : this.setState({ comment: event.target.value })
  }

  validateEmail = (email: string) => {
    if (email.length > 0) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
      if (emailRegex.test(email)) {
        return true
      } else {
        this.setState({
          error:
            "Väärä sähköpostiosoite. Sähköpostiosoitteen tulee olla muodossa 'esimerkki@osoite.fi'",
        })
        return false
      }
    } else {
      return true
    }
  }

  handleSubmit = async (/*event: SyntheticInputEvent<HTMLInputElement>*/): Promise<
    void
  > => {
    if (this.validateEmail(this.state.email) && this.state.comment.length > 0) {
      this.setState({ buttonText: 'Ladataan', loading: true })
      const { LoginStore } = this.props

      const body = {
        email: this.state.email,
        feedback: this.state.comment,
        username: LoginStore.username,
        customerName: LoginStore.firstName + ' ' + LoginStore.lastName
      }

      const headers = getHeaders()

      const req = new Request(`${apiUrl}/feedback/submit`, {
        body: JSON.stringify(body),
        headers: headers,
        method: 'POST',
        mode: 'cors',
      })

      const res = await fetch(req)
      if (res.status == 200) {
        return this.setState({ messageSent: true, loading: false })
      } else {
        this.setState({ buttonText: 'Virhe, yritä lähettää myöhemmin', loading: false })
      }
    }
  }

  render(): JSX.Element {
    return (
      <div className="wrapper container feedback">
        {!this.state.messageSent && (
          <React.Fragment>
            <h1>Ota yhteyttä tai jätä meille palautetta</h1>
            {/* <h5>Kysymykseesi voi löytyä vastaus <a href="https://www.neve.fi/asiakaspalvelu/assarin-usein-kysytyt-kysymykset" target="_blank" rel="noopener noreferrer">Assari UKK:ssa</a></h5> */}
            <label htmlFor="email">
              Sähköpostiosoite (jos haluat vastauksen)
              <input
                id="email"
                type="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
              {this.state.error.length > 0 && (
                <p className="error-message">{this.state.error}</p>
              )}
            </label>
            <label htmlFor="textarea">
              Viesti
              <textarea
                id="textarea"
                className="feedback-area"
                rows={15}
                value={this.state.comment}
                onChange={this.handleChange}
                maxLength={2000}
              />
            </label>
            <button
              className="submit green"
              onClick={this.handleSubmit}
              disabled={this.state.loading}
            >
              {this.state.buttonText}
            </button>
          </React.Fragment>
        )}

        {this.state.messageSent && (
          <div className="feedback-success">
            <h1>Kiitos yhteydenotosta!</h1>
          </div>
        )}
      </div>
    )
  }
}

export default inject('LoginStore')(observer(Feedback))
