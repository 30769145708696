// @flow

import moment from 'moment'
import { UsageData } from '../store/index'

const getUsageData = (type: string): UsageData | null => {
  let usageData: UsageData | null = null
  const start = moment()
    .startOf('year')
    .format('YYYY-MM-DD')
  const end = moment()
    .endOf('year')
    .format('YYYY-MM-DD')
  const frame = 'year'
  // TODO Correctly type.
  const data: any[] = [] // eslint-disable-line

  if (type === 'electricity' || type === 'sahko') {
    usageData = {
      title: 'Sähkönkulutus',
      type: 'electricity',
      frame,
      level: 'month',
      unit: '',
      start,
      end,
      data,
    }
  } else if (type === 'water' || type === 'vesi') {
    usageData = {
      title: 'Vedenkulutus',
      type: 'water',
      frame,
      level: 'month',
      unit: 'kuutiota',
      start,
      end,
      data,
    }
  } else if (type === 'districtheat' || type === 'kaukolampo') {
    usageData = {
      title: 'Kaukolämmön kulutus',
      type: 'heat',
      frame,
      level: 'month',
      unit: '',
      start,
      end,
      data,
    }
  }

  if (usageData) return { ...usageData }

  return null
}

export default getUsageData
