export const themeDetails = {
    company: 'Tornion Energia Oy',
    brandName: '',
    theme: 'tke',
    services: ['districtheat', 'electricity', 'contractInfo'],
    incomingServices: [],
    customerServiceEmail: 'tke.asiakaspalvelu@tornionenergia.fi',
    feedbackEmail: 'tke.asiakaspalvelu@tornionenergia.fi',
  }
  
  export const Options = {
    quarterlyDataAllowed: true, 
  }
  
  export const styledComponentsThemes = {
    default: {
      'nav-color': '#005685',  //#004D91 te blue
      'inline-nav-color': '#005685',  //#181512 te black
      'nav-color:hover': '#009fe3',
    },
    inverted: {
      'nav-color': '#fff',
      'inline-nav-color': '#fff',
      'nav-color:hover': '#fff',
    },
  }
  
  export const textStrings = {
    home: {
      introduction: 'Tervetuloa Online-palveluumme!',
      subHeader:
        'Täältä löydät ajankohtaiset tiedot liittyen energiankulutukseesi.',
    },
  }
  
  export const footerContent = {
    contactInfo: [
      {
        icon: 'fa fa-map-marker-alt',
        type: 'address',
        href: '',
        title: 'Tornion Energia Oy - Y-tunnus: 1801477-7',
        content: ['Asemakatu 5', '95420 Tornio'],
      },
      {
        icon: 'fa fa-envelope',
        type: 'mixed',
        href: 'tel:0401445111',
        href2: 'mailto:tke.asiakaspalvelu@tornionenergia.fi',
        title: 'Asiakaspalvelu',
        content: ['puh. 040 1445 111', 'tke.asiakaspalvelu@tornionenergia.fi'],
      },
    ],
    // Fault reports information for TKE isn't currently in use.
    // Lets not remove these if we need them back later for the footer element.
    // faultReports: {
    //   contacts: [
    //     {
    //       href: 'tel:020366020', 
    //       text: 'puh. ',
    //       linkText: '020 366 020',
    //     },
    //   ],
    // },
    website: 'https://www.tornionenergia.fi',
  }
